<header
  class="flux-header clearfix"
  [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme"
  [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType"
  [attr.theme-status]="ThemeStatus ? 'dark' : 'light'"
>
  <div class="flux-header-wrapper clearfix">
    <div class="flux-header-navbar">
      <div class="flux-left-header clearfix pointerr">
        <a>
          <img
            src="assets/picLogo.png"
            class="img-fluid"
            style="max-width: 375px; height: auto"
          />
        </a>
      </div>

      <div class="flux-right-header clearfix ml-auto">
        <div
          class="flux-left-header clearfix pointerr"
          *ngIf="_router.url.includes('login') || (userID && userType != 'ADM')"
        >
          <a>
            <img
              src="assets/faysalfunds_logo.png"
              class="img-fluid"
              style="max-width: 320px; height: auto"
            />
          </a>
        </div>
        <ul>
          <li
            class="dashboard"
            *ngIf="_router.url.includes('user-management') && userType != 'ADM'"
          >
            <button mat-button (click)="GotoLink('batch-search')">
              Dashbaord
            </button>
          </li>

          <li
            class="dashboard"
            *ngIf="userType == 'ADM' && _router.url.includes('screening')"
          >
            <button mat-button (click)="GotoLink('user-management')">
              User Management
            </button>
          </li>
          <li *ngIf="!_router.url.includes('login')">
            <button
              id="vertical-dots"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Logout"
              style="color: #8d6e63"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="LogOut()">
                <span>Sign Out</span>
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
