<div class="face">
	<div class="band">
		<div class="red"></div>
		<div class="white"></div>
		<div class="blue"></div>
	</div>
	<div class="eyes"></div>
	<div class="dimples"></div>
	<div class="mouth"></div>
</div>

<h1 style="color:white">404: Oops! Something went wrong!</h1>
<h3 style="color: white;text-align: center;">Please use the "Back" button in your web browser to return to the previous page. There you can correct whatever problems were identified in this error or select another action. </h3>

<br>


