import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationRouting } from '../routes/authentication.routing'
import { AuthenticationService } from '../service/authentication.service';
import { JwtInterceptor } from '../interceptor/JWTInterceptor';
import { AuthenticationComponent } from '../authentication.component';
import { AuthGuard } from '../guard/authentication.guard';


import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "src/app/modules/shared.module";

@NgModule({
  declarations: [AuthenticationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthenticationRouting,
    MatSnackBarModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    NgxSpinnerModule,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap:[AuthenticationComponent]
})
export class AuthenticationModule {}
