import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/errorPages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/pages.module').then((m) => m.PagesModule),
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./authentication/module/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },

  {
    path: 'pages',
    loadChildren: () =>
      import('./modules/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/pages.module').then((m) => m.PagesModule),
  },
  { path: '**', redirectTo: '/pageNotFound' },
  { path: 'pageNotFound', component: PageNotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
