// ENDPOINTS:
export enum APIRoutes {
  getAllUsers = 'api/get_all_users/',
  toggleAct = 'api/deactivate_user/',
  updateQuota = 'api/process_quota/',
  createUser = 'api/create_user/',
  renewLicenseAPI = 'api/reset_quota/',
  screenCase = 'api/generate_entity_report/',
  getQutaDetails = 'api/get_quota/',
  createJobAPI = 'api/create_job/',
  getStatusAPI = 'api/get_status/',
}